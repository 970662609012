/* html,
body {
  width: 100%;
  overflow-x: hidden;
} */

body {
  font-size: 16px;
  transition: background 0.2s ease-in, color 0.2s ease-in;
  font-family: -apple-system, BlinkMacSystemFont, "Nunito", "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
  margin: 0;
  padding: 0;
}

/* *,
::before,
::after {
  box-sizing: border-box;
} */

#root {
  height: 100vh;
  margin: 0;
  overflow: hidden;
  background-color: #fff;
  
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

body::-webkit-scrollbar,
#root::-webkit-scrollbar {
  display: none;
  -webkit-appearance: none;
  width: 0;
  height: 0;
}